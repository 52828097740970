export default {
  name: 'Sebastian Klaus - Softwareentwicklung und Beratung',
  owner: 'Sebastian Klaus',
  url: 'https://www.sk-seb.de/',
  urlShort: 'sk-seb.de',
  phone: '+4936520423072',
  phoneLabel: '0365 - 20423072',
  phoneInternational: '+49-36602-20423072',
  street: 'Leumnitzer Str. 9',
  city: 'Gera',
  postCode: '07546 ',
  ustId: 'DE320264365'
};
