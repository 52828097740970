import React from 'react';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'de' }}>
        <meta charSet="utf-8" />
        <meta name="description" content="Sebastian Klaus - Softwareentwicklung und Beratung" />
      </Helmet>
      <main className="text-gray-900 mb-10">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
